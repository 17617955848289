/* eslint-disable valid-jsdoc */

/**
 * show/hide Crisp btn on the right-bottom of page by current pathname
 * @param show 
 */
export const setCrispDisplayState = (show) => {
  (window as any)?.$crisp?.do?.(`chat:${show ? 'show' : 'hide'}`);
  (window as any)?.$crisp?.do?.(`chat:${show ? 'open' : 'close'}`);
}

/**
 * open/close crisp chat box
 * @param show 
 */
export const setCrispChaBoxState = (show = true) => {
  const action = (window as any)?.$crisp?.do
  if (!action) {
    console.log('chat box is loading')
    return
  }
  (window as any)?.$crisp?.do?.(`chat:${show ? 'open' : 'close'}`)
}

export const getCrispUnreadMessage = () => {
  return (window as any)?.$crisp?.get('chat:unread:count') || 0
}

export const isCrispLoaded = () => {
  return Boolean((window as any)?.$crisp?.is)
}

export const isCrispInitialed = () => {
  return (window as any).$crisp.is && document.getElementById('crisp-chatbox')
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const toggleCustomerService = ({ onVisibleChange } = { onVisibleChange: (visible: boolean) => { } }) => {
  const crisp = (window as any)?.$crisp
  if (!crisp?.is) {
    return false
  }

  crisp?.off?.('chat:closed');

  crisp?.push?.(
    [
      'on',
      'chat:closed',
      function () {
        // hidden when closed
        setTimeout(() => {
          // Q: do we need to clear it?
          // A: no
          setCrispDisplayState(false)
          onVisibleChange?.(false)
          return false
        }, 300)
      }
    ]
  )

  if (crisp?.is?.('chat:opened')) {
    setCrispChaBoxState(false)
    setTimeout(() => {
      setCrispDisplayState(false)
    }, 300);
  } else {
    // opened, true
    setCrispDisplayState(true);
    setCrispChaBoxState(true);
    onVisibleChange?.(true)

    const root = document.getElementById('crisp-chatbox');

    if (root) {
      const [chatBox, button] = root.children[0]?.children || []
      // @ts-ignore
      button && (button.setAttribute('style', 'display: block !important; opacity: 0; pointer-events: none'))
      // @ts-ignore
      chatBox && (chatBox.setAttribute('style', 'right: 110px !important; width: 400px !important;'))
    }
  }
}