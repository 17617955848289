import type { Locale } from './i18n-config'
import { isEmpty } from 'lodash-es'

import enPageLanguage from './dictionaries/en-US.json'
import enErrorcode from './errorcode/en-US/errorcode.json'

import zhPageLanguage from './dictionaries/zh-CN.json'
import zhErrorcode from './errorcode/zh-CN/errorcode.json'

import idPageLanguage from './dictionaries/id-ID.json'
import idErrorcode from './errorcode/id-ID/errorcode.json'

import jpPageLanguage from './dictionaries/ja-JP.json'
import jpErrorcode from './errorcode/ja-JP/errorcode.json'

import twPageLanguage from './dictionaries/zh-TW.json'
import twErrorcode from './errorcode/zh-TW/errorcode.json'


import trPageLanguage from './dictionaries/tr-TR.json'
import trErrorcode from './errorcode/tr-TR/errorcode.json'

import msMYPageLanguage from './dictionaries/ms-MY.json'
import msMYErrorcode from './errorcode/ms-MY/errorcode.json'

// We enumerate all dictionaries here for better linting and typescript support
// We also get the default import for cleaner types
const enLanguage = { errorcode: enErrorcode, ...enPageLanguage }
const zhLanguage = { errorcode: zhErrorcode, ...zhPageLanguage }
const idLanguage = { errorcode: idErrorcode, ...idPageLanguage }
const jpLanguage = { errorcode: jpErrorcode, ...jpPageLanguage }
const twLanguage = { errorcode: twErrorcode, ...twPageLanguage }
const trLanguage = { errorcode: trErrorcode, ...trPageLanguage }
const msMYLanguage = { errorcode: msMYErrorcode, ...msMYPageLanguage }

export const dictionaries = {
  'en-US': () => enLanguage,
  'en-us': () => enLanguage,
  'zh-CN': () => zhLanguage,
  'zh-cn': () => zhLanguage,
  'id': () => idLanguage,
  'id-ID': () => idLanguage,
  'ja-JP': () => jpLanguage,
  'ja': () => jpLanguage,
  'zh-TW': () => twLanguage,
  'zh-tw': () => twLanguage,
  'tr-TR': () => trLanguage,
  'tr': () => trLanguage,
  "ms-MY": () => msMYLanguage,
  "ms": () => msMYLanguage
}

// convert crowdin language to locale
const crowdinLocaleMaps = {
  'en': 'en-US',
  'en-US': 'en-US',
  'en-us': 'en-US',
  'zh-CN': 'zh-CN',
  'zh-cn': 'zh-CN',
  'id-ID': "id-ID",
  'id': "id-ID",
  'ja-JP': 'ja-JP',
  'ja': 'ja-JP',
  'zh-TW': 'zh-TW',
  'zh-tw': 'zh-TW',
  'tr-TR': 'tr-TR',
  'tr': "tr-TR",
  "ms-MY": "ms-MY",
  "ms": "ms-MY"
}

// const log = process.env.DEV_LOCAL_HOST_ENV === 'true' ? console.log : () => void 0

// get matching language in crowdin
export const getMatchCrowdinLang = locale => {
  if (!locale || locale === 'en') return crowdinLocaleMaps['en-us']
  return crowdinLocaleMaps[locale] || crowdinLocaleMaps['en-us']
}

const CROWDIN_LOCAL_STORAGE_KEY = 'crowdin-seeker'
const CROWDIN_TIMESTAMP_KEY = '__crowdin_timestamp__'
const CROWDIN_LANGUAGE_KEY = '__crowdin_language__'

const isServer = typeof window === 'undefined'

// cache dictionary in server
const cacheSeekerDic = Object.create(null)

const getCacheData = (lang: string) => {
  if (isServer) {
    return cacheSeekerDic[lang]
  } else {
    // get localStorage data
    return localStorage.getItem(CROWDIN_LOCAL_STORAGE_KEY)
  }
}

const setCacheData = ({ match, data }: { match: string; data: any }) => {
  if (isServer) {
    cacheSeekerDic[match] = JSON.stringify(data)
  } else {
    localStorage.setItem(CROWDIN_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }
}

// 根据 crowdin 返回的时间戳，判断是否过期。
const getLocalData = ({ timestamp, lang }: { timestamp: number; lang: string }) => {
  if (!timestamp || !lang) return null

  // get cache data
  let localData: any = getCacheData(lang)

  if (!localData) return null

  // parse
  localData = JSON.parse(localData)

  // checked empty
  if (!localData[CROWDIN_LANGUAGE_KEY] || !localData[CROWDIN_TIMESTAMP_KEY]) return null

  // checked language
  if (localData[CROWDIN_LANGUAGE_KEY] !== lang) {
    return null
  }

  // checked expired timestamp
  const isExpired = localData[CROWDIN_TIMESTAMP_KEY] >= timestamp
  // log('Dictionary isExpired::', isExpired, localData[CROWDIN_TIMESTAMP_KEY], timestamp)

  // return data
  return isExpired ? localData : null
}

export const getDictionarySync = (lang: string) => {
  const match = getMatchCrowdinLang(lang)
  const localData = getCacheData(match)
  if (!localData) {
    return
  }
  try {
    return JSON.parse(localData)
  } catch (e) {
    console.log(e)
  }
}

export const getDictionary = async (locale: Locale) => {
  try {
    const languages = await fetch(
      'https://distributions.crowdin.net/7ebf57665448382c18ccd49ef5z/manifest.json'
    )
      .then((res) => {
        return res.json()
      })
      .catch((error) => {
        throw new Error('fetch manifest error');
      })

    const match = getMatchCrowdinLang(locale)
    const matchCacheData = getLocalData({ timestamp: languages.timestamp, lang: match })
    // log('getDictionary::', { locale, match, matchCacheData, isServer, cacheSeekerDic });
    if (matchCacheData) {
      return matchCacheData
    }

    const dicUrl = `https://distributions.crowdin.net/7ebf57665448382c18ccd49ef5z/content/dictionaries/${match}.json?timestamp=${languages.timestamp}`
    const dic = await fetch(dicUrl)
      .then((res) => {
        return res.json()
      })
      .catch((error) => {
        throw new Error('fetch dictionary error');
      })

    const errorCodeUrl = `https://distributions.crowdin.net/7ebf57665448382c18ccd49ef5z/content/errorcode/${match}/errorcode.json?timestamp=${languages.timestamp}`
    const errorcode = await fetch(errorCodeUrl)
      .then((res) => {
        return res.json()
      })
      .catch((error) => {
        throw new Error('fetch errorcode error');
      })

    if (isEmpty(dic)) {
      return dictionaries[locale]?.() || dictionaries['en-us']()
    }

    // merge data
    const data = {
      ...dic,
      errorcode,
      [CROWDIN_TIMESTAMP_KEY]: languages.timestamp,
      [CROWDIN_LANGUAGE_KEY]: match,
    }

    // save data to cache
    setCacheData({ match, data })

    return data
  } catch (error) {
    return dictionaries[locale]?.() || dictionaries['en-us']()
  }
}