import configuredAxios from 'helpers/configuredAxios'
import {
  cleanObjectValues,
  validateSourceAndDevice,
  viewCompanySourceTracker,
  viewHrSourceTracker
} from 'components/ReportTracker/util'

// company view tracker
const fetchViewCompany = ({ id, payload }) => {
  if (!id) return
  const state = 'public'
  const newPayload = cleanObjectValues(payload)
  const isValidValue = validateSourceAndDevice({
    obj: viewCompanySourceTracker,
    source: newPayload?.source,
    device: newPayload?.device
  })
  if (!isValidValue) return
  const axios = configuredAxios('company', state)
  return axios.post(`/${id}/view`, newPayload)
}

// hr view tracker
const fetchViewHr = ({ payload }) => {
  if (!payload?.recruiter_id || !payload?.company_id) return
  const state = 'public'
  const newPayload = cleanObjectValues(payload)
  const isValidValue = validateSourceAndDevice({
    obj: viewHrSourceTracker,
    source: newPayload?.source,
    device: newPayload?.device
  })
  if (!isValidValue) return
  const axios = configuredAxios('jobseeker', state)
  return axios.post(`/viewed_hr`, newPayload)
}

export { fetchViewCompany, fetchViewHr }
