import queryString from 'query-string'
import configuredAxios from 'helpers/configuredAxios'
import { getCookie } from 'helpers/cookies'
import { cache } from 'react'
const fetchJobsListService = (payload) => {
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  return axios.get(`/search?${queryString.stringify(payload)}`)
}

const fetchHotJobsListService = (countryId) => {
  const endpointType = 'public'
  const axios = configuredAxios('recommendation', endpointType, false)
  return axios.get(`/hot-jobs?page=1&size=15&country_id=${countryId}`)
}

const queryOnlineStatus = (user_ids, role = 'recruiter') => {
  const endpointType = 'public'
  const axios = configuredAxios('jobApplication', endpointType, false)
  return axios.post('/chats/query-online-status', {
    "user_ids": user_ids,
    "role": role
  })
}

const fetchCompanyRemoteJobFilter = (id) => {
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  return axios.get(`/company-remote-job-filter?company_id=${id}`)
}

const fetchRemoteJobsListService = (payload) => {
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  return axios.get(`/search-remote-jobs?${queryString.stringify(payload)}`)
}

const fetchHotJobTitles = cache(() => {
  const endpointType = 'public'

  const axios = configuredAxios('recommendation', endpointType, false)

  return axios.get(`/hot-job-titles`)
})

const fetchRecommendJobsListService = (payload) => {
  const endpointType = 'public'
  const axios = configuredAxios('recommendation', endpointType, false)
  const sessionid = getCookie('sessionid') ? true : false
  const params = {
    country_id: 167,
    page: 1,
    size: 15,
    ...payload
  }

  const url = sessionid ? '/job-recommendations' : '/hot-jobs'
  return axios.get(`${url}?${queryString.stringify(params)}`)
}


const fetchOtherCountryHotJobsListService = (page = 1, size = 5) => {
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  const params = {
    page,
    size,
  }

  const url = '/other-country-hot-jobs'
  return axios.get(`${url}?${queryString.stringify(params)}`, {
    headers: {
      'Country-Id': '241'
    }
  })
}


const fetchOtherCountrySimilarSearchListService = (page = 1, size = 5, otherQuery = {}) => {
  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  const params = {
    page,
    size,
    query_fields: 'job_title,company_name',
    ...otherQuery
  }
  // {{JOB_URL}}/search-other-country-jobs?query=test company&query_fields=job_title,company_name&page=1&size=10
  const url = '/search-other-country-jobs'
  return axios.get(`${url}?${queryString.stringify(params)}`, {
    headers: {
      'Country-Id': '241'
    }
  })
}


export {
  fetchJobsListService,
  fetchHotJobsListService,
  queryOnlineStatus,
  fetchCompanyRemoteJobFilter,
  fetchRemoteJobsListService,
  fetchHotJobTitles,
  fetchRecommendJobsListService,
  fetchOtherCountrySimilarSearchListService,
  fetchOtherCountryHotJobsListService
}
