import { useRouter } from "next/navigation"
import { useEffect, useState, useTransition } from "react"
import { setCookie } from "./cookies"
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { getCookie } from "./cookies"
export const useLazyConnectLogin = () => {
  const [connecting, translation] = useTransition()
  const modulesLoaded = useSharedData('MODULE_LOADED') ?? {}
  const connected = modulesLoaded['remote-login-modal']
  const [moduleLoading, setModuleLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const router = useRouter()
  const refreshed = getCookie('startConnectLogin')
  const [firstShow, setFirstShow] = useState(!refreshed)
  const startConnect = () => {

    if (connected || connecting || moduleLoading) {
      return
    }
    setCookie('startConnectLogin', '1')
    setModuleLoading(true)
    translation(() => {
      router.refresh()
    })
  }

  const showLoginModal = () => setShowModal(true)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (showModal) {
      if (!connected) {
        setLoading(true)
        startConnect()
      } else {
        if (firstShow) {
          setTimeout(() => {
            postNotification('SHOW_LOGIN_MODAL')
            setShowModal(false)
            setFirstShow(false)
            setLoading(false)
          }, 1000)
        } else {
          postNotification('SHOW_LOGIN_MODAL')
          setShowModal(false)
          setLoading(false)
        }
      }
    }
  }, [connected, showModal])
  return { showLoginModal, startConnect, connecting, connected, loading }
}

