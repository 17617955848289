export const map = {
  xp_lvl_id: ['xp_lvls'],
  job_type_id: ['job_types'],
  degree_id: ['degrees'],
  no_of_employee_range_id: ['no_of_employee_ranges'],
  company_size_id: ['company_sizes'],
  industry_id: ['industry_lists'],
  job_category_id: ['job_category_lists'],
  job_benefit_id: ['job_benefit_lists'],
  job_attractive_reason_id: ['job_attractive_reasons'],
  company_benefit_id: ['company_benefit_lists'],
  company_culture_id: ['company_culture_lists'],
  company_financing_stage_id: ['company_financing_stage_lists'],
  country_id: ['country_lists'],
  region_id: ['country_lists', 'region_lists'],
  location_id: ['country_lists', 'region_lists', 'location_lists'],
  chat_report_id: ['chat_report_lists'],
  notice_period_id: ['notice_period_lists'],
  subscibe_job_frequency_id: ['subscibe_job_frequency_lists'],
  report_job_reason_id: ['report_job_reasons'],
  report_talent_reason_id: ['report_talent_reasons'],
  salary_range_id: ['salary_ranges'],
  social_type_id: ['social_types'],
  resume_source_id: ['resume_source_lists'],
  language_id: ['language_lists'],
  job_language_id: ['job_languages'],
  gender_id: ['gender_lists'],
  pipeline_stage_id: ['pipeline_stage_lists'],
  resume_last_updated_time_id: ['resume_last_updated_time_lists'],
  job_function_id: ['job_function_lists'],
  report_interview_reason_id: ['report_interview_reasons'],
  report_recruiter_reason_id: ['report_recruiter_reasons'],
  currency_id: ['currency_lists'],
  work_xp_id: ['work_xps'],
  education_id: ['educations'],
  salary_range_filter_id: ['salary_range_filters'],

  daily_salary_range_filter_id: ['daily_salary_range_filters'],
  monthly_salary_range_filter_id: ['monthly_salary_range_filter'],
  hourly_salary_range_filter_id: ['hourly_salary_range_filters'],
  annually_salary_range_filter_id: ['annually_salary_range_filters'],

  user_status_id: ['users'],
  job_status_id: ['jobs'],
  company_document_status_id: ['company_documents'],
  recruiter_document_status_id: ['recruiter_documents'],
  job_seeker_work_xp_status_id: ['job_seeker_work_xps'],
  job_seeker_pref_status_id: ['job_seeker_prefs'],
  job_seeker_edu_status_id: ['job_seeker_edus'],
  saved_job_status_id: ['saved_jobs'],
  saved_candidate_status_id: ['saved_candidates'],
  blacklisted_user_status_id: ['blacklisted_users'],
  blacklisted_company_status_id: ['blacklisted_companies'],
  job_seeker_resume_status_id: ['job_seeker_resumes'],
  job_application_status_id: ['job_applications'],
  dialogue_status_id: ['dialogues'],
  chat_status_id: ['chats'],
  interview_result_status_id: ['interview_result'],
  feature_banner_id: ['feature_banners'],
  recruiter_role_permission_id: ['recruiter_role_permissions'],
  main_job_function_id: ['main_job_function_lists'],
  function_job_title_id: ['main_job_function_lists', 'sub_function_list', 'job_titles'],
  work_arrangement_id: ['work_arrangement_lists'],
  timezones_id: ['timezone_lists'],
  user_identity_lists: ['user_identity_lists'],
}

export const getIdPath = (key: string) => {
  return map[key]
}
