/* eslint-disable react/prop-types */
import { isMobile } from 'react-device-detect'

import { ReaderTPromise as M } from 'app/models/abstractModels/monads'
import jobSource from 'helpers/jobSource'
import { receiveNotification } from 'bossjob-remote/dist/clientStorage'
import { createChat } from 'app/[lang]/chat/[chat_id]/interpreters/services/chat'
import { registInterpreter } from 'app/models/abstractModels/util'
import { ToChatStatus } from '../abstractModels/ChatNow'
import { getCookie, getSourceCookie } from 'helpers/cookies'
import { updateImState } from 'store/actions/chat/imState'
import { check } from 'app/[lang]/chat/[chat_id]/interpreters/services/chat'
import { fetchSwitchJobService } from 'store/services/jobs/fetchSwitchJob'
import * as fbq from 'lib/fpixel'
import { getCountryKey, getLang } from 'helpers/country'
import { getSharedData, watchSharedData } from 'bossjob-remote/dist/clientStorage'
import { isEmpty } from 'lodash-es'
const isJobRemote = (jobDetail) => {
  return jobDetail?.work_arrangement?.id == 3 || jobDetail?.work_arrangement_id == 3
}
const hasRemotePreference = (preferences) => {
  return preferences?.filter((preference) => preference?.work_arrangement?.id == 3)?.length > 0
}
const hasLocalPreference = (preferences) => {
  return (
    preferences?.filter(
      (preference) => preference?.work_arrangement?.id == 1 || preference?.work_arrangement?.id == 2
    )?.length > 0
  )
}

const interpreter = registInterpreter((command) =>
  command.cata({
    isLogin: () =>
      M.do(() => {
        const userCookie = getCookie('user')
        const authCookie = getCookie('sessionid')
        return userCookie && authCookie
      }),
    hasChatData: () => M.do((context) => context?.jobDetail?.chat),
    fetchChatData: () =>
      M.do((context) => {
        // const { jobDetail: { recruiter_id } } = context
        const recruiter_id = context?.jobDetail?.recruiter_id || context?.jobDetail?.recruiter?.id
        return check(recruiter_id).then((respones) => respones.data.data?.[0])
      }),
    parseToChatStatus: (chatDetail) =>
      M.do(async (context) => {
        const { jobDetail, getJobPreferences, getSkip, isLogin } = context
        const skip = getSkip?.()
        // const userCookie = getSharedData('ME') || {}
        const userCookie = await new Promise((resolve, reject) => {
          const me = getSharedData('ME') || {}
          if (isEmpty(me)) {
            watchSharedData('ME', (data) => {
              if (data?.data) {
                resolve(data.data)
              }
            })
          } else {
            resolve(me)
          }
        })

        const isSg = getCountryKey() === 'sg'
        const jobPreferences = userCookie?.job_preferences || []

        const { getUserData } = context
        const userData = getUserData()
        const jobNeedSgId = jobDetail?.job_identity_restrictions?.length > 0
        const noSgId = !userData.user_identity
        const sgIdNotMatch = !jobDetail?.job_identity_restrictions?.map?.(item => item.id)?.includes(userData?.user_identity?.id)
        const { external_apply_url, id } = jobDetail

        const isJobPreferences = userCookie?.is_job_preferences // 用户是否有职位偏好
        const isProfileCompleted = userCookie?.is_profile_completed
        const whenClickLoginStatus = isLogin() // 点击chatNow时，当前的登录状态
        // 已登录 || 未登录状态下，登录成功后是否有职位偏好
        const needFillProfileComplete = !isProfileCompleted && (whenClickLoginStatus || (!whenClickLoginStatus && isJobPreferences))

        console.log('jobPreferences', {
          userData,
          userCookie,
          isProfileCompleted,
          jobPreferences,
          isJobPreferences,
          needFillProfileComplete,
          whenClickLoginStatus,
          skip
        })

        if (external_apply_url) {
          return ToChatStatus.externalLink(external_apply_url)
        } else if (isSg && jobNeedSgId && noSgId) {
          return ToChatStatus.noSgId
        } else if (isSg && jobNeedSgId && sgIdNotMatch) {
          return ToChatStatus.sgIdNotMatch
        } else if (needFillProfileComplete) {
          return ToChatStatus.completeUserProfile
        } else if (!jobPreferences?.length && !skip && (whenClickLoginStatus || (!whenClickLoginStatus && isJobPreferences) || (!whenClickLoginStatus && isProfileCompleted && !isJobPreferences))) {
          const isRemote = isJobRemote(jobDetail)
          return isRemote ? ToChatStatus.noJobPreferenceRemote : ToChatStatus.noJobPreferenceLocal
        }
        else if (chatDetail.is_exists && id === chatDetail.job_id && jobPreferences?.length) {
          return ToChatStatus.existSameJob
        } else if (chatDetail.is_exists && id !== chatDetail.job_id && jobPreferences?.length) {
          return ToChatStatus.existDiffrentJob
        } else if (jobPreferences?.length || skip) {
          return ToChatStatus.notExist
        }
      }),
    redirectToLogin: () =>
      M.do((context) => {
        const { showLogin, jobDetail } = context
        const { id } = jobDetail
        sessionStorage.setItem('chatJobId', id)
        showLogin()
        return new Promise((res) => {
          receiveNotification('HIDE_LOGIN_MODAL', () => {
            const sessionid = getCookie('sessionid')
            console.log('HIDE_LOGIN_MODAL', sessionid)
            if (sessionid) {
              res(true)
            } else {
              sessionStorage.removeItem('chatJobId')
              res(false)
            }
          })

        })
        // router.push('/get-started', { scroll: true })
      }),
    redirectToExternal: (link) =>
      M.do((context) => {
        const { router } = context
        router.push(link, { scroll: true })
      }),
    modalChangeChattingJob: (chatDetail) =>
      M.do((context) => {
        const { showModal } = context
        showModal(chatDetail)
      }),
    requestChangeJob: (chatDetail) =>
      M.do((context) => {
        const { jobDetail } = context
        const { id } = jobDetail
        return fetchSwitchJobService({
          status: 'protected',
          job_id: id,
          applicationId: chatDetail.job_application_id
        }).then(() => chatDetail.id || chatDetail.chat_id)
      }),
    redirectToChat: (chatId) =>
      M.do((context) => {
        const { router } = context
        const lang = getLang()
        window.location.href = `/${lang}/chat/` + chatId
        //  router.push(`/${lang}/chat/` + chatId, { scroll: true })
      }),
    modalCompleteFile: () =>
      M.do((context) => {
        const {
          showCompleteModal,
          jobDetail: { id },
          router
        } = context
        const source = getSourceCookie()
        showCompleteModal(() => {
          sessionStorage.setItem('isChatRedirect', `/chat-redirect/${id}?source=${source}`)
          router.push('/jobseeker-complete-profile')
        })
      }),
    modalJobPreferenceFile: (remote) =>
      M.do((context) => {
        const { showPreferenceModal } = context
        return new Promise((res, rej) => {
          showPreferenceModal(remote, res, rej)
        })
      }),
    createNewChat: () =>
      M.do((context) => {
        const { jobDetail, dispatch, source } = context
        const { id } = jobDetail
        // const source = getSourceCookie()
        return createChat(id, { source, device: isMobile ? 'mobile_web' : 'web' }).then(
          (result) => {
            const chatId = result.data.data.id
            const newData = {
              ...result.data?.data?.job_application,
              initiated_role: result.data?.data?.initiated_role,
              delete_status: result.data?.data?.delete_status,
              chatStatus: result.data?.data?.status,
              self_role: 'jobseeker'
            }
            dispatch(updateImState({ chatId, imState: newData }))
            const userInfo = getCookie('user')
            // Send new chat event to FB Pixel and google analytic
            if (
              process.env.ENV === 'production' &&
              typeof window !== 'undefined' &&
              userInfo &&
              jobDetail &&
              !jobDetail.chat?.is_exists
            ) {
              if (window.gtag) {
                window?.gtag?.('event', 'new_chat', {
                  user_id: userInfo.id,
                  email: userInfo.email,
                  job_id: jobDetail.id,
                  main_function: jobDetail.function?.main_function ?? null
                })
              }

              if (window.fbq) {
                fbq.event('new_chat', {
                  user_id: userInfo.id,
                  job_id: jobDetail.id
                })
              }

              if (window.ttq) {
                window.ttq.track('SubmitForm', {
                  user_id: userInfo.id,
                  email: userInfo.email,
                  job_id: jobDetail.id,
                  main_function: jobDetail.function?.main_function ?? null
                })
              }
            }
            return chatId
          }
        )
      }),
    modalAddSgId: () => M.do((context) => {
      const { showAddSgIdModal } = context
      return showAddSgIdModal()
    }),
    modalSgIdNotMatch: () => M.do((context) => {
      const { showSgIdNotMatchModal } = context
      return showSgIdNotMatchModal()
    }),
    modalUserProfile: () => M.do((context) => {
      const { showCompleteUserProfile } = context
      return showCompleteUserProfile()
    }),
  }),

)

export default interpreter
