import queryString from 'query-string'
import configuredAxios from 'helpers/configuredAxios'

const fetchJobsForYou = (payload) => {

  const endpointType = 'public'
  const axios = configuredAxios('job', endpointType, false)
  // return axios.get(`/filter?${queryString.stringify(payload)}`)
  return axios.get(`/search?${queryString.stringify(payload)}`)
}

export { fetchJobsForYou }