'use client';

import React, { useContext, useState, createContext } from 'react';
import { ConfigType } from 'app/types';
import { ManageProfileData, fetchUserOwnDetail } from './service';

interface ProviderData {
	config?: Partial<ConfigType>
	profile?: ManageProfileData
	fetchProfile?: () => void
}

const MangeProfile = createContext<
	ProviderData | undefined
>(undefined);

interface Props extends React.PropsWithChildren<ProviderData> {
	name?: any
}
export function MangeProfileProvider(
	{ children, ...props }: Props) {
	const [profile, setProfile] = useState(props.profile);
	const fetchProfile = () => {
		fetchUserOwnDetail().then(profile => {
			setProfile(profile.data)
		})
	}
	return (
		<MangeProfile.Provider value={{ ...props, profile, fetchProfile }}>
			{children}
		</MangeProfile.Provider>
	);
}

export function useManageProfileData() {
	const context = useContext(MangeProfile);
	if (context === undefined) {
		throw new Error('useManageProfileData must be used within a MangeProfileProvider');
	}
	return context;
}
