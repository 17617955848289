import configuredAxios from 'helpers/configuredAxios'

export const submitFeedbackService = (payload) => {
  const axios = configuredAxios('job', '')
  const formData = new FormData()

  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value)
  });

  return axios.post(`/job-search-feedbacks/create`, formData,)
}
