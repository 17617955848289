import configuredAxios from 'helpers/configuredAxios'
import { removeEmptyOrNullValues } from 'helpers/formatter'

type getSchoolProps = {
  is_active?: 0 | 1,
  country_id?: number,
  degree_id?: any
}

const getSchool = ({ is_active, country_id, degree_id }: getSchoolProps = {}) => {

  const params = removeEmptyOrNullValues({ is_active, country_id, degree_id })
  const axios = configuredAxios('config')

  return axios.get('/school/lists', {
    params
  })
}

export { getSchool }
