import { getCookie, setCookie } from 'helpers/cookies'
import { useEffect, useRef } from 'react'
import { useRouter } from 'next/navigation'
export const useLazyMode = () => {
  const router = useRouter()
  const handled = useRef(false)
  const lazyoff = getCookie('lazyoff')
  useEffect(() => {
    if (!lazyoff) {
      const handleLoad = () => {
        setCookie('lazyoff', '1')
        handled.current = true
        router.refresh()
      }
      // window.onload = handleLoad
      setTimeout(() => {
        if (!handled.current) {
          handleLoad()
        }
      }, 10)
      // return window.removeEventListener('load', handleLoad)
    }
  }, [])
  return !lazyoff
}