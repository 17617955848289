import configuredAxios from 'helpers/configuredAxios'

const fetchRecruiterSubscriptionFeatureService = () => {
  const axios = configuredAxios('data', null)
  return axios.get('/users/subscription_feature')
}

export {
  fetchRecruiterSubscriptionFeatureService
}
