/**
 * why add this?
 * there are some comm logic used in pages, we should create a unique way to get all values. cause site implement i18n,
 * those keys are different with different languages
 *
 *  calculate salary range
 * @param {object} params data come from search and config
 * @return {object}
 */
export const getSalaryRange = ({
  hourlyFilter,
  dailyFilter,
  monthlyFilter,
  annuallyFilter,
  salaryType,
  salaryTypeLists,
  selectedSalaries
}: {
  // the below data must comme from config
  salaryTypeLists: any[]
  hourlyFilter: any[]
  monthlyFilter: any[]
  dailyFilter: any[]
  annuallyFilter: any[]

  // the below comes from search queries/values in generally
  salaryType: string
  selectedSalaries: any[]
}) => {
  const handleSalary = (salaryRanges = [], salaryList) => {
    const selected = salaryRanges
      .map((seo) => salaryList.find((item) => item['seo-value'] === seo))
      .filter((a) => a)
    const from = selected.map((item) => item.from).join(',')
    const to = selected.map((item) => item.to).join(',')
    return [from, to]
  }

  const salaryTypeStr = salaryType
  const salaryTypeObj = salaryTypeLists.find((v) => v.value === salaryTypeStr) || {}
  let salaryTypeSeoType = salaryTypeObj['seo-value']

  const initType = {
    monthly: {
      salaryList: monthlyFilter,
      salaryKey: 'salary'
    },
    daily: {
      salaryList: dailyFilter,
      salaryKey: 'daily_salary'
    },
    hourly: {
      salaryList: hourlyFilter,
      salaryKey: 'hourly_salary'
    },
    annually: {
      salaryList: annuallyFilter,
      salaryKey: 'annually_salary'
    }
  }

  if (!initType[salaryTypeSeoType]) {
    console.error(
      'we accept a salaryType values ' + `'${salaryTypeSeoType}', it's a bug we should fix`
    )
    salaryTypeSeoType = 'monthly'
  }

  const { salaryList, salaryKey } = initType[salaryTypeSeoType] || {}
  const [salaryFrom, salaryTo] = handleSalary(selectedSalaries, salaryList)
  return {
    salaryFrom,
    salaryTo,
    salaryKey,
    salaryType: salaryTypeSeoType
  }
}

interface SalaryType {
  Monthly?: string
  Daily?: string
  Hourly?: string
  payType?: string
  Annually?: string
  Unpaid?: string
}

interface SalaryRange {
  salaryValue: string | undefined
  salaryTypeName: string
}

export const getSalaryValues = (
  salaryType: SalaryType | undefined,
  salaryTypeId: number | undefined,
  jobSalary: { [key: string]: string | undefined },
  work_arrangement_id?: number
): SalaryRange => {
  const {
    Monthly = 'Monthly',
    Daily = 'Daily',
    Hourly = 'Hourly',
    Unpaid = 'Unpaid',
    Annually = 'Annually',
    payType = 'payType'
  } = salaryType || {}

  const {
    local_salary_range_value,
    salary_range_value,
    salary_range,
    local_daily_salary_range_value,
    daily_salary_range,
    local_hourly_salary_range_value,
    hourly_salary_range,
    local_annually_salary_range_value,
    annually_salary_range_value,
  } = jobSalary
  const isRemoteJob = work_arrangement_id === 3

  return {
    salaryValue: (function () {
      switch (salaryTypeId) {
        case 4:
          return (isRemoteJob ? annually_salary_range_value : '') || local_annually_salary_range_value || salary_range_value || annually_salary_range_value
        case 2:
          return (isRemoteJob ? daily_salary_range : '') || local_daily_salary_range_value || salary_range_value || daily_salary_range
        case 3:
          return (isRemoteJob ? hourly_salary_range : '') || local_hourly_salary_range_value || salary_range_value || hourly_salary_range
        default:
          return (isRemoteJob ? salary_range_value : "") || local_salary_range_value || salary_range_value || salary_range
      }
    })(),
    salaryTypeName: (function () {
      switch (salaryTypeId) {
        case 4:
          return Annually
        case 2:
          return Daily
        case 3:
          return Hourly
        case 1:
          return Monthly
        default:
          return Unpaid
      }
    })()
  }
}
