import {
  getCookie,
  userKey, redirectUrl as redirectWithUrl
} from './cookies'
import { getLang } from './country'

export const pushToUrl = ({ targetUrl, path = '' }) => {
  const sessionid = getCookie('sessionid')
  const user = getCookie(userKey)
  const lang = getLang()
  if (!sessionid) {
    const params = new URLSearchParams()
    params.append(redirectWithUrl, path)
    params.append('lang', lang)
    window.open(`${targetUrl}/redirect?${params.toString()}`)
  } else {
    const params = new URLSearchParams()
    params.append('sessionid', sessionid)
    params.append(userKey, JSON.stringify(user) ?? '')
    params.append(redirectWithUrl, path)
    params.append('lang', lang)
    window.open(`${targetUrl}/redirect?${params.toString()}`)
  }
  return path
}

export const pushToResume = (path = '') => {
  const resumeUrl = process.env.RESUME_TEMP_URL
  pushToUrl({ targetUrl: resumeUrl, path: path })
}

export const pushToBossjobAI = () => {
  const bossjobAiUrl = process.env.BOSSJOB_AI_URL
  pushToUrl({ targetUrl: bossjobAiUrl })
}