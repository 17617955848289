import { taggedSum } from 'daggy'
import { Free } from 'fantasy-frees'
import { CommonActions } from '../util'
const DO = F => Free.liftFC(F)

export const ToChatStatus = taggedSum('ChatStatus', {

    notLogin: [],
    externalLink: ['link'],
    existSameJob: [],
    notCompleteFile: [],
    noJobPreferenceLocal: [],
    noJobPreferenceRemote: [],
    existDiffrentJob: [],
    notExist: [],
    noSgId: [],
    sgIdNotMatch: [],
    completeUserProfile: [],
})
export const Actions = taggedSum('ChatNowActions', {
    isLogin: [],
    hasChatData: [],
    fetchChatData: [],
    parseToChatStatus: ['chatData'],
    redirectToLogin: [],
    modalCompleteFile: [],
    modalJobPreferenceFile: ['remote'],
    redirectToExternal: ['link'],
    modalChangeChattingJob: ['chatData'],
    redirectToChat: ['chatId'],
    requestChangeJob: ['chatData'],
    createNewChat: [],
    modalAddSgId: [],
    modalSgIdNotMatch: [],
    modalUserProfile: []
})

export const chatNowScript = () => DO(Actions.isLogin)
    .chain(login => {
        if (!login) {
            return DO(Actions.redirectToLogin)
                .chain(didLogin => {
                    if (didLogin) {
                        return chatNowScript()
                    } else {
                        return DO(CommonActions.just('end'))
                    }
                })
        } else {
            return DO(Actions.hasChatData)
                .chain(chatData => {
                    if (chatData) {
                        return doChatScript(chatData)
                    } else {
                        return DO(Actions.fetchChatData)
                            .chain(doChatScript)
                    }
                })
        }
    })

const doChatScript = chatData => DO(Actions.parseToChatStatus(chatData))
    .chain(status => status.cata({
        notLogin: () => DO(Actions.redirectToLogin),
        noSgId: () => DO(Actions.modalAddSgId)
            .chain(finish => {
                if (finish) {
                    return doChatScript(chatData)
                } else {
                    return DO(CommonActions.just('end'))
                }
            }),
        // .andThen(doChatScript(chatData)),
        sgIdNotMatch: () => DO(Actions.modalSgIdNotMatch),
        completeUserProfile: () => DO(Actions.modalUserProfile),
        notCompleteFile: () => DO(Actions.modalCompleteFile),
        noJobPreferenceLocal: () => DO(Actions.modalJobPreferenceFile(false))
            .andThen(doChatScript(chatData)),

        noJobPreferenceRemote: () => DO(Actions.modalJobPreferenceFile(true))
            // update job preferences outside
            .andThen(doChatScript(chatData)),
        externalLink: link => DO(Actions.redirectToExternal(link)),
        existSameJob: () => DO(Actions.redirectToChat(chatData.id || chatData.chat_id)),
        existDiffrentJob: () => DO(Actions.modalChangeChattingJob(chatData)),
        notExist: () => DO(Actions.createNewChat)
            .chain(chatId => DO(Actions.redirectToChat(chatId)))
    }))

export const switchJobScript = chatData => DO(Actions.requestChangeJob(chatData))
    .chain(chatId => DO(Actions.redirectToChat(chatId)))
