import { getCookie } from 'helpers/cookies'
import { getCountryKey } from 'helpers/country'

export const transState = (time: string | number, translations: Record<string, any> = {}) => {
  const nowDate = new Date().getTime()
  const lastTime = new Date(time).getTime()
  if (nowDate - lastTime < 1000 * 60 * 60) {
    return {
      state: 1,
      text: translations?.online || 'Online'
    }
  } else if (nowDate - lastTime < 1000 * 60 * 60 * 8) {
    return {
      state: 0,
      text: translations?.now || 'Active just now'
    }
  } else if (nowDate - lastTime < 1000 * 60 * 60 * 24) {
    return {
      state: 0,
      text: translations?.withinToday || 'Active today'
    }
  } else if (nowDate - lastTime < 1000 * 60 * 60 * 168) {
    return {
      state: 0,
      text: translations?.withinWeek || 'Active this week'
    }
  } else {
    return {
      state: 0,
      text: translations?.withinMonth || 'Active this month'
    }
  }
}
export const transStateNew = (
  time: string | number,
  translations: Record<string, any> = {},
  isOnline: number
) => {
  const nowDate = new Date().getTime()
  const lastTime = new Date(time).getTime()
  const diffTime = nowDate - lastTime
  if (isOnline) {
    return {
      state: 1,
      text: translations?.online || 'Online'
    }
  } else if (time === -1) {
    return {
      state: 0,
      text: translations?.withinMonth || 'Active this month'
    }
  } else if (diffTime < 1000 * 60 * 60 * 8) {
    return {
      state: 0,
      text: translations?.now || 'Active just now'
    }
  } else if (diffTime < 1000 * 60 * 60 * 24) {
    return {
      state: 0,
      text: translations?.withinToday || 'Active today'
    }
  } else {
    return {
      state: 0,
      text: translations?.withinWeek || 'Active this week'
    }
  }
}
export const isSameDay = (startTime?: string, endTime?: string) => {
  if (!endTime) return false
  const startTimeMs = new Date(startTime.replace(/-/g, '/')).setHours(0, 0, 0, 0)
  const endTimeMs = new Date(endTime.replace(/-/g, '/')).setHours(0, 0, 0, 0)
  return startTimeMs === endTimeMs
}

export const transDate = (date, allTime = null) => {
  if (!date) return ''
  const newDate = new Date(date.replace(/-/g, '/'))
  const chinaDate = newDate.toDateString()
  const chinaDateArray = chinaDate.split(' ')
  const englishTime = `${chinaDateArray[2]} ${chinaDateArray[1]}  ${chinaDateArray[3]}`
  if (allTime) {
    return `${englishTime}, ${chinaDateArray[0]}, ${date.substr(11, 5)}`
  }
  return englishTime
}

export const serveIsMobile = (userAgent: string) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
}

export const isServer = () => typeof window === 'undefined'

export const isMobileDevice = () => {
  if (isServer()) {
    const { headers } = require('next/headers')
    const headersList = headers()
    const userAgent = headersList.get('User-Agent')
    return serveIsMobile(userAgent)
  } else {
    const userAgent = navigator.userAgent
    return serveIsMobile(userAgent)
  }
}

const rad = (d) => {
  return (d * Math.PI) / 180.0
}

export const getDistance = (Plat1, Plng1, Plat2, Plng2) => {
  if (!Plat1 || !Plng1 || !Plat2 || !Plng2) {
    return 0
  }
  const lat1 = Number(Plat1)
  const lng1 = Number(Plng1)
  const lat2 = Number(Plat2)
  const lng2 = Number(Plng2)
  const radLat1 = rad(lat1)
  const radLat2 = rad(lat2)
  const a = radLat1 - radLat2
  const b = rad(lng1) - rad(lng2)
  let s =
    2 *
    Math.asin(
      Math.sqrt(
        Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
      )
    )
  s = s * 6378.137 // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000 // enput km

  const distance = s
  let distance_str = ''

  if (distance >= 1) {
    distance_str = distance.toFixed(1) + ' km'
  } else {
    distance_str = distance * 1000 + ' m'
  }

  console.info(distance, distance_str)
  return distance_str
}

export const routes = (url: string) => {
  window.location.replace(url)
}

export function omit<T extends Record<string | number, any>, K extends keyof T>(
  obj: T,
  keys: Array<K | string> // string 为了某些没有声明的属性被omit
): Omit<T, K> {
  const clone = {
    ...obj
  }
  keys.forEach((key) => {
    if ((key as K) in clone) {
      delete clone[key as K]
    }
  })
  return clone
}

export const htmlToPlain = (html) => {
  const div = document.createElement('div')
  div.innerHTML = html
  const result = div.innerText || div.textContent || ''
  return result
}

export const isRemoteJob = (jobOrArrangementId) =>
  jobOrArrangementId === 3 || jobOrArrangementId?.work_arrangement_id === 3

export const addParams = (params, location) => {
  if (!location || !location.id) return params
  console.log(location)
  const { type, id } = location
  const keyMap = {
    country: 'pref_country_id',
    region: 'pref_region_id',
    location: 'location_id'
  }
  const key = keyMap[type] || 'location_id'
  params[key] = id
  return params
}

export function convertSpecialCharacters(str) {
  if (!str) return
  let newStr = str.toLowerCase()
  const charMap = {
    'c++': 'cpp',
    'c#': 'csharp',
    '&': '~',
    '-': '_',
    '/': '--',
    ' ': '-'
  }
  Object.keys(charMap).forEach((key) => {
    // eslint-disable-next-line no-useless-escape
    const escapedKey = key.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
    const regex = new RegExp(escapedKey, 'ig')
    newStr = newStr.replace(regex, charMap[key])
  })
  return newStr
}

export function revertSpecialCharacters(str) {
  if (!str) return
  let newStr = str.toLowerCase()
  const revertMap = {
    cpp: 'c++',
    csharp: 'c#',
    '~': '&',
    '--': '/',
    '-': ' ',
    _: '-'
  }
  Object.keys(revertMap).forEach((key) => {
    const regex = new RegExp(key, 'ig')
    newStr = newStr.replace(regex, revertMap[key])
  })
  return newStr
}

export const addEventTag = (eventName: string, data?: Record<string, any>) => {
  if (typeof window !== 'undefined' && window.gtag) {
    if (!data) {
      window.gtag('event', eventName)
      return
    }
    window.gtag('event', eventName, data)
  }
}

export const addEventTagForH5 = (eventName: string, data?: Record<string, any>) => {
  const visitedPage = getCookie('visitedPage')
  const isSg = getCountryKey() === 'sg'
  if (visitedPage === 'sg-h5' && isSg) {
    addEventTag(eventName, data)
  }
}
