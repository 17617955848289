export const utmCampaignKey = 'utmCampaign'
export const applyPendingJobId = 'applyPendingJob'
export const isFromCreateResume = 'isFromCreateResume'
export const isGenerateResume = 'isGenerateResume'
export const appLinkUrl = 'http://bossjob.com/app'

export const adjustLink = 'https://app.adjust.com/14g2jvm8';

const API_BASE_PATH = typeof window === 'undefined' ? process.env.SERVER_API_BASE_PATH : '/bossjob-api-server'

export const urlMap = {
  bosshunt: process.env.DATA_BOSSHUNT_URL,
  search: process.env.SEARCH_BOSSJOB_URL,
  data: `${API_BASE_PATH}/data`,
  auth: `${API_BASE_PATH}/authentication`,
  job: typeof window === 'undefined' ? process.env.JOBS_PATH : `${API_BASE_PATH}/jobs`,
  chat: `${API_BASE_PATH}/chat/api/v3`,
  jobApplication: `${API_BASE_PATH}/job-applications`,
  reco: `${API_BASE_PATH}/reco/api/v1`,
  payment: `${API_BASE_PATH}/payment/api/v1`,
  academy: `${API_BASE_PATH}/academy/api/v1`,
  jobseeker: typeof window === 'undefined' ? process.env.JOBSEEKER_PATH : `${API_BASE_PATH}/jobseekers`,
  company: typeof window === 'undefined' ? process.env.COMPANY_PATH : `${API_BASE_PATH}/companies`,
  config: typeof window === 'undefined' ? process.env.CONFIG_PATH : `${API_BASE_PATH}/config`,
  im: typeof window === 'undefined' ? process.env.IM_BOSSJOB_URL : `${API_BASE_PATH}/im`,
  recruiters: `${API_BASE_PATH}/recruiters`,
  resumes: `${API_BASE_PATH}/resumes`,
  resumeTemplate: `${API_BASE_PATH}/resume-template`,
  recommendation: `${API_BASE_PATH}/recommendations`,
  notification: `${API_BASE_PATH}/notification`,
  companies: typeof window === 'undefined' ? process.env.COMPANY_PATH : `${API_BASE_PATH}/companies`,
  'apiGo': typeof window === 'undefined' ? process.env.API_GO_PATH : `${API_BASE_PATH}/api-go`,
}

export type serviceType = keyof typeof urlMap

export const mapUrl = (type: serviceType) => {
  return urlMap[type] || ''
}

export const getFetchUrl = (type: serviceType) => {
  if (!type) return ''
  return mapUrl(type)
}

export enum SiteTokenEnum {
  bossjob = 'Authorization',
  bosshunt = 'Bossjob-Token'
}

export function getPublicHeader() {
  return {
    // 'Country-Id': `${countryId}`,
    'Role-Id': '1'
  }
}

export const buildParams = (params) =>
  Object.keys(params)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&')
